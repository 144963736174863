.brand-h5 {
    line-height: 150%;
    font-size: 16px;

    @screen xs {
        font-size: 16px;
    }
    @screen sm {
        font-size: 20px;
    }
}
