.button-base {
    @apply px-5;
    font-size: 1rem;
    line-height: 1.17rem;
    height: 2.625rem;
    white-space: nowrap;

    &:focus {
        outline: none;
    }

    &:disabled {
        @apply bg-gray-7 hover:bg-gray-7 text-gray-7;
    }
}
