.brand-body1 {
    line-height: 150%;
    font-size: 16px;

    @screen md {
        font-size: 20px;
    }

    p {
        @apply my-4;
    }

    img {
        @apply mx-auto;
    }

    a {
        @apply underline;
    }
}